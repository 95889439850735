
<template>
    <div class="platform-bottom-layout">
        <div class="platform-buttom-list">
          <div :class="pageType==='list'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('list')">合同列表</div>
          <div v-if="pageType==='detail'" :class="pageType==='detail'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('detail')">合同详情</div>
          <div v-if="pageType==='edit'" :class="pageType==='edit'? 'bottom-button-active':'bottom-button'" @click="handleChangeTag('edit')">合同编辑</div>
        </div>

        <component :is="pageType + 'Component'" ref="bottom"  />
    </div>
</template>

<script>
export default {
  components: {
    listComponent: (resolve) => {
      require(['@/components/investmentContract/List'], resolve)
    },
    createComponent: (resolve) => {
      require(['@/components/investmentContract/modalComponents/CreateContract'], resolve)
    },
    editComponent: (resolve) => {
      require(['@/components/investmentContract/Edit'], resolve)
    },
    detailComponent: (resolve) => {
      require(['@/components/investmentContract/modalComponents/detail'], resolve)
    }
  },
  data () {
    return {
    }
  },
  created () {
  },
  methods: {
    handleChangeTag (val) {
      this.$store.commit('set_contract_pageType', val)
    }

  },
  computed: {
    pageType () {
      return this.$store.state.investmentContract.pageType
    }
  },
  watch: {
  }
}
</script>
